<template>
    <v-container class="my-10 my-md-16">
        <v-row class="d-flex flex-row pa-3 heading">
            <v-col cols="12">
                <h1 class="text-left">{{ $t("navbar.withdrawals") }}</h1>
                <p class="text-left">
                    {{ $t("navbar.withdrawalsDesc") }}
                </p>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <h2 class="text-center">{{$t('panelWithdrawals.titlePending')}}</h2>
            </v-col>
        </v-row>

        <v-row class="profile-skeleton rounded-xl mx-3 px-2 ">
            <template v-if="loading">
                <v-col v-for="i in 3" :key="i" cols="12" sm="6" md="4">
                    <v-skeleton-loader type="card" class="pa-4 profile rounded-xl mt-4 mb-4" outlined>
                        <div class="d-flex justify-space-between align-center mb-2">
                            <v-skeleton-loader type="text" width="40%" />
                            <v-skeleton-loader type="chip" width="32%" />
                        </div>
                        <v-divider class="my-3"></v-divider>
                        <div class="d-flex justify-space-between mb-1">
                            <v-skeleton-loader type="text" width="20%" />
                            <v-skeleton-loader type="text" width="40%" />
                        </div>
                        <div class="d-flex justify-space-between">
                            <v-skeleton-loader type="text" width="40%" />
                            <v-skeleton-loader type="text" width="40%" />
                        </div>
                    </v-skeleton-loader>
                </v-col>
            </template>
            <template v-else>
                <v-row>
                    <v-col v-if="pendingWithdrawals.length === 0" cols="12" class="d-flex justify-center align-center">
                        <p class="text-center d-flex align-center mt-4 mb-4">
                            <v-icon left class="mr-2">mdi-information-slab-circle-outline</v-icon>
                            {{$t('panelWithdrawals.notFound') }}                        </p>
                    </v-col>
                    <v-col v-else v-for="(card, index) in pendingWithdrawals" :key="index" 
                        cols="12"
                        :sm="pendingWithdrawals.length === 1 ? 12 : (pendingWithdrawals.length === 2 ? 6 : 6)"
                        :md="pendingWithdrawals.length === 1 ? 12 : (pendingWithdrawals.length === 2 ? 6 : 4)"
                        class="mt-4 mb-4">
                        <v-card class="pa-4 profile rounded-xl" outlined>
                            <div class="d-flex justify-space-between align-center mb-2">
                                <span class="text-h5 font-weight-bold purple-text">${{ card.amount }}</span>
                                <v-chip small color="amber lighten-4" text-color="amber darken-2"
                                    class="font-weight-medium">
                                    {{ statusNameTranslate(card.status_withdrawal_name) }}
                                </v-chip>
                            </div>
                            <v-divider class="my-3"></v-divider>
                            <div class="d-flex justify-space-between mb-1">
                                <span class="caption grey--text">{{ $t('panelWithdrawals.payDate') }}</span>
                                <span class="caption">{{ card.created_at }}</span>
                            </div>
                            <div class="d-flex justify-space-between">
                                <span class="caption grey--text">{{ $t('panelWithdrawals.payMethod') }}:</span>
                                <span class="caption d-flex align-center">
                                    <v-icon small left :color="getPaymentIconColor(card.payment_method)">
                                        {{ getPaymentIcon(card.payment_method) }}
                                    </v-icon>
                                    {{ getNameMethod(card.payment_method) }}
                                </span>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>

            </template>
        </v-row>

        <v-row class="mb-4 mt-10">
            <v-col>
                <h2 class="text-center mb-4">{{ $t('panelWithdrawals.titleCompleted') }}</h2>
                <v-data-table :headers="headers" :items="withdrawals" :loading="loading"
                    class="profile rounded-xl mx-3 px-2 py-4" :items-per-page="perPage"
                    :server-items-length="totalWithdrawals" :page.sync="page" :footer-props="{
                        itemsPerPageOptions: [50, 100, 200, 300, -1],
                        showFirstLastPage: true
                    }" @update:page="fetchWithdrawals" @update:items-per-page="changePerPage">

                    <template v-slot:item.status_withdrawal_name="{ item }">
                        <v-chip :color="getStatusColor(item.status_withdrawal_name)" dark small>
                            {{ statusNameTranslate(item.status_withdrawal_name) }}
                        </v-chip>
                    </template>

                    <template v-slot:item.amount="{ item }">
                        $ {{ item.amount }} USD
                    </template>

                    <template v-slot:item.payment_method="{ item }">
                        {{ getNameMethod(item.payment_method) }}
                    </template>

                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from '@/axios.js';
export default {
    data() {
        return {
            pendingWithdrawals: [],
            withdrawals: [],
            totalWithdrawals: 0,
            loading: false,
            page: 1,
            perPage: 50,
            search: '',
        };
    },
    computed: {
        headers() {
            return [
                { text: this.$t('panelWithdrawals.payAmount'), value: 'amount' },
                { text: this.$t('panelWithdrawals.payMethod'), value: 'payment_method' },
                { text: this.$t('panelWithdrawals.payStatus'), value: 'status_withdrawal_name' },
                { text: this.$t('panelWithdrawals.reasonRejected'), value: 'reason_rejection' },
                { text: this.$t('panelWithdrawals.payDateWithdrawal'), value: 'created_at' },
            ];
        },

    },

    created() {
        this.fetchWithdrawals();
    },

    methods: {
        getPaymentIcon(paymentMethod) {
            switch (paymentMethod) {
                case "Paypal":
                    return "mdi-paypal";
                case "Bank":
                    return "mdi-bank";
                case "Credit Card":
                    return "mdi-credit-card";
                case "crypto":
                    return "mdi-bitcoin";
                default:
                    return "mdi-cash";
            }
        },
        getPaymentIconColor(paymentMethod) {
            switch (paymentMethod) {
                case "Paypal":
                    return "blue";
                case "Bank Transfer":
                    return "green";
                case "Credit Card":
                    return "red";
                default:
                    return "grey";
            }
        },

        getStatusColor(statusName) {
            if (statusName.toLowerCase() === 'solicitado') {
                return '#f29339';
            } else if (statusName.toLowerCase() === 'en proceso') {
                return '#8e84c0';
            } else if (statusName.toLowerCase() === 'pagado') {
                return '#2e8b57';
            } else if (statusName.toLowerCase() === 'rechazado') {
                return '#f44336';
            }
        },

        getNameMethod(method) {
            if (method === 'bank') {
                return this.$t('panelWithdrawals.payBank');
            } else if (method === 'crypto') {
                return this.$t('panelWithdrawals.payCripto');
            } else if (method === 'cosmo') {
                return this.$t('panelWithdrawals.payCosmo');
            } else if (method === 'paypal') {
                return this.$t('panelWithdrawals.payPaypal');
            }
        },

        async fetchWithdrawals() {
            this.loading = true;
            try {
                const response = await axios.get('/get-my-withdrawals', {
                    params: {
                        page: this.page,
                        per_page: this.perPage,
                        search: this.search,
                    }
                });

                const withdrawals = response.data.withdrawals.data;

                // Filtrar pendientes y no pendientes en un solo paso
                this.pendingWithdrawals = [];
                this.withdrawals = [];

                withdrawals.forEach(withdrawal => {
                    if (withdrawal.status_withdrawal_name === 'Solicitado') {
                        this.pendingWithdrawals.push(withdrawal);
                    } else {
                        this.withdrawals.push(withdrawal);
                    }
                });

                this.totalWithdrawals = response.data.total;
            } catch (error) {
                console.error(error);
            } finally {
                this.loading = false;
            }
        },

        statusNameTranslate(statusName) {
            switch (statusName) {
                case 'Solicitado':
                    return this.$t('panelWithdrawals.payRequested');
                case 'En proceso':
                    return 'In process';
                case 'Pagado':
                    return this.$t('panelWithdrawals.payPaid');
                case 'Rechazado':
                    return this.$t('panelWithdrawals.payRejected');
                default:
                    return statusName;
            }
        },


        changePerPage(newPerPage) {
            this.perPage = newPerPage;
            this.page = 1; // Reiniciar a la primera página al cambiar los elementos por página
            this.fetchWithdrawals();
        },
    },
};
</script>

<style>
.heading h1 {
    font-size: 1.5rem;
    margin-bottom: 5px;
}

.heading p {
    font-size: 0.8rem;
    color: rgb(151, 138, 206);
    font-weight: 600;
}

.profile {
    gap: 20px;
    color: rgb(70, 70, 70);
    -webkit-box-shadow: 0px 7px 16px 0px rgba(224, 224, 224, 1);
    -moz-box-shadow: 0px 7px 16px 0px rgba(224, 224, 224, 1);
    box-shadow: 0px 7px 16px 0px rgba(224, 224, 224, 1);
}

.profile-skeleton { 
    color: rgb(70, 70, 70);
    -webkit-box-shadow: 0px 7px 16px 0px rgba(224, 224, 224, 1);
    -moz-box-shadow: 0px 7px 16px 0px rgba(224, 224, 224, 1);
    box-shadow: 0px 7px 16px 0px rgba(224, 224, 224, 1);
}

.purple-text {
  color: #9786ce;
}

.withdrawal-card {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
}

.v-chip {
  font-size: 0.75rem !important;
}

.caption {
  font-size: 0.75rem !important;
}
</style>